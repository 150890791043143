<!-- 学生状态 -->
<template>
    <div>
        <div class="content-main">
            <div class="filter-wrap">
                <expand-filter
                    :close-width="960"
                    :formData="formData"
                    marginBottom="0px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @inputInput="handleFilterInput"
                    @changeCascader="handleFilterCascader"
                >
                    <template slot="other-btn">
                        <div class="teacher-student">
                            <el-tooltip class="item" effect="dark" content="按组织架构展示" placement="top-start">
                                <div
                                    class="teacher"
                                    :class="role == 'teacher' ? 'active-style' : ''"
                                    @click="setRole('teacher')"
                                >
                                    <img :src="role == 'teacher' ? teaN : teaY" alt="">
                                </div>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="按学生展示" placement="top-start">
                                <div
                                    class="student"
                                    :class="role == 'student' ? 'active-style' : ''"
                                    @click="setRole('student')"
                                >
                                    <img :src="role == 'student' ? stuY : stuN" alt="">
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </expand-filter>
            </div>
            <!-- 统计数据 -->
            <statistics-data
                :statistics-data="statisticsData"
                :style="{
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                }"
            ></statistics-data>
            <!-- 表格 -->
            <div
                class="content"
                :style="{
                    height: contentHeight + 'px',
                    overflowY: role == 'teacher' ? 'auto' : 'hidden',
                }"
            >
                <!-- 班级列表 -->
                <el-tree
                    v-if="role == 'teacher'"
                    :data="treeData"
                    node-key="organId"
                    :key="treeKey"
                    ref="table"
                    :indent="0"
                    :default-expanded-keys="defaultExpandIds"
                    icon-class=" "
                    :props="{
                        children: 'child',
                        label: 'organName',
                    }"
                >
                    <span
                        class="custom-tree-node"
                        slot-scope="{node, data}"
                    >
                        <div class="my-tree-content">
                            <div
                                v-if="data.child && data.child.length"
                                class="icon"
                                :class="{up: node.expanded}"
                            >
                                <i class="el-icon-arrow-right"></i>
                            </div>
                            <div class="title">{{ node.label }}</div>
                            <div class="info">
                                <div
                                    v-for="item in infoDist"
                                    :key="`${data.name}${item.keyName}`"
                                    class="info-item"
                                >
                                    {{ item.text }}{{ data[item.keyName] }}人
                                </div>
                            </div>
                        </div>
                    </span>
                </el-tree>
                <!-- 学生列表 -->
                <div class="stu-wrapper" v-else>
                    <table-data
                        v-loading="loadingTable"
                        ref="table"
                        :config="tableConfig"
                        :tableData="tableData"
                    >
                        <template #studentName="{data}">
                            <span
                                style="color: #3c7fff; cursor: pointer"
                                @click="detailsOA(data)"
                                v-if="data.status == 2 && statusFormData.stuOAStatus == 1 && isHasSchoolLeaveRecordAnalysisMenu"
                                >{{ data.studentName }}</span
                            >
                            <span v-else>{{ data.studentName }}</span>
                        </template>
                        <!-- 出勤状态 -->
                        <template v-slot:status="slotData">
                            <el-link
                                :type="getAttendancesStatusBycode(slotData.data.status).type"
                                :underline="false"
                                @click="openAttendanceStatus(slotData.data.studentId,slotData.data.status,slotData.data)"
                            >
                                {{getAttendancesStatusBycode(slotData.data.status).name}}
                            </el-link>
                        </template>
                        <!-- 标识 -->
                        <template v-slot:flag="slotData">
                            <el-tag
                                :type="getIdentifyingByCode(slotData.data.undiscipline).type"
                                :style="{
                                    cursor: slotData.data.undiscipline == '1' ? 'pointer' : 'auto'
                                }"
                                effect="plain"
                                @click="handleUndisciplineClick(slotData.data)"
                            >
                                {{getIdentifyingByCode(slotData.data.undiscipline).name}}
                            </el-tag>
                        </template>
                        <!-- 操作 -->
                        <template v-slot:operate="slotData">
                            <el-button
                                type="text"
                                @click="showHistoryAttendance(slotData.data, 'history')"
                            >
                                历史出勤
                            </el-button>
                            <el-button
                                type="text"
                                @click="showHistoryAttendance(slotData.data, 'change')"
                            >
                                修改记录
                            </el-button>
                        </template>
                    </table-data>
                    <Pagination
                        :total="pageQuery.total"
                        :page.sync="pageQuery.pageNum"
                        :limit.sync="pageQuery.pageRow"
                        @pagination="getStudentList()"
                    />
                </div>
            </div>
        </div>
        <!-- 设置弹窗 -->
        <dialog-wrapper
            :dialogObj="statusSettingDialogObj"
            @handleClose="handleStatusSettingClose"
        >
            <el-form
                :model="statusFormData"
                ref="formRef"
                label-posiiton="right"
            >   
                <!-- 设置1：初始化状态 -->
                <div class="setting-item init-status">
                    <div class="title">设置1</div>
                    <el-form-item label="" prop="status">
                        <div class="tip">
                            设置初始化状态
                            <i class="el-icon-warning"></i>
                            <span class="tip-text">系统将在每天0:00初始化学生状态</span>
                        </div>
                        <el-radio-group v-model="statusFormData.status">
                            <el-radio
                                v-for="item in statusOptions"
                                :key="item.id"
                                :label="item.id"
                            >{{item.name}}</el-radio>
                        </el-radio-group>
                        <div v-if="!statusFormData.status" class="require-tip">您还未设置初始化状态，请设置</div>
                    </el-form-item>
                </div>
                <!-- 设置2：更改状态必填开关 -->
                <div class="setting-item change-require">
                    <div class="title">设置2</div>
                    <el-form-item label="更改状态时修改原因必填">
                        <el-switch v-model="statusFormData.changeReasonRequired"></el-switch>
                    </el-form-item>
                </div>
                <!-- 设置3：更改状态必填开关 -->
                <div class="setting-item change-require">
                    <div class="title">设置3</div>
                    <el-form-item label="" prop="status">
                        <div class="tip">
                            请假状态跟学生请假单关联
                            <el-tooltip popper-class="tooltip-popper" class="first_enter" content="关联后，从当天开始，在请假时间内，学生状态将自动置为请假" placement="top-start" :offset="30">
                                <i class="el-icon-question label-description"></i>
                            </el-tooltip>
                        </div>
                        <el-radio-group v-model="statusFormData.stuOAStatus">
                            <el-radio
                                v-for="item in stuOAStatusOptions"
                                :key="item.id"
                                :label="item.id"
                            >{{item.name}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div style="text-align: right;">
                    <el-button @click="handleStatusSettingClose">取消</el-button>
                    <el-button :loading="statusButtonLoading" @click="handleStatusSubmit" type="primary">确定</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
        <!-- 修改出勤状态弹窗 -->
        <dialog-wrapper
            :dialogObj="attendanceStatusDialogObj"
            @handleClose="handleAttendanceStatusClose"
        >
            <el-form
                :model="attendanceStatusFormData"
                label-width="0px"
                ref="formRef"
                label-posiiton="right"
            >
                <el-form-item label="" prop="id">
                    <span class="label">状态</span>
                    <el-radio-group v-model="attendanceStatusFormData.id">
                        <el-radio
                            v-for="item in attendanceStatusOptions"
                            :key="item.id"
                            :label="item.id"
                        >{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" prop="updateReason">
                    <span :class="{'required': statusFormData.changeReasonRequired, 'label': true }">修改原因</span>
                    <el-input
                        v-model="attendanceStatusFormData.updateReason"
                        type="textarea"
                        rows="5"
                        maxlength="200"
                        show-word-limit
                        placeholader="如：长期不来，违纪回家3天，或病假等"
                    ></el-input>
                </el-form-item>

                <div style="text-align: right;">
                    <el-button @click="handleAttendanceStatusClose">取消</el-button>
                    <el-button :loading="attendanceStatusButtonLoading" @click="handleAttendanceStatusSubmit" type="primary">确定</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
    </div>
</template>

<script>

import {debounce,DialogWrapper,Pagination} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import selectTree from "./Sub/selectTree";
import ExpandFilter from "./Sub/ExpandFilter";
import statisticsData from "@/components/scrollWrapper/SummaryStudentStatus/statisticsData.vue";
import { listToTree, hasPermission } from "@/libs/utils.js";
import {mapState} from "vuex";
import onResize from "@/mixins/onResize";
import RecordsList from '@/libs/pageListOptimal.js';
import { format } from 'echarts';

export default {
    name: "SummaryStudentStatusBaseScrollWrapper",
    mixins: [onResize],
    components: {
        selectTree,
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        statisticsData,
    },
    data() {
        const _this = this;
        return {
            role: '', //展示维度， teacher:班级列表，student:学生列表
            // 班级列表的筛选条件
            teacherFormData: [
                {
                    type: "date",
                    placeholder: "日期",
                    key: "attendanceDate",
                    value: "",
                    picker: {
                        disabledDate(time) {
                            return time.getTime() > Date.now() || time.getTime() < _this.$moment().subtract(1, 'months').date(1).subtract(1, 'days').valueOf();
                        },
                    },
                    clearable:false,
                    valueFormat: "yyyy-MM-dd",
                },
                {
                    key:"organId",
                    label: "",
                    placeholder: "班级",
                    type: "cascader",
                    value: [],
                    list: [],
                    cascaderProps: {
                        label: "name",
                        multiple: true,
                        value: "id",
                        // checkStrictly: true,
                        emitPath:false
                    },
                    filter(data) {
                        return data.organType != 1;
                    },
                },
            ],
            // 学生列表的筛选条件（比班级列表多的）
            studentFormData: [
                {
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "出勤状态",
                    key: "status",
                    list: [
                        {
                            label: "未到",
                            value: "3",
                        },
                        {
                            label: "到校",
                            value: "1",
                        },
                        {
                            label: "请假",
                            value: "2",
                        },
                    ],
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "姓名",
                    key: "studentName",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "学号",
                    key: "studentNo",
                },
                {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "唯一号",
                    key: "studentCode",
                },

            ],
            formData: {
                data: [],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["checkStudentStatus"]
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["checkStudentStatus"]
                    },
                    {
                        type: "primary",
                        text: "设置",
                        fn: "setting",
                    },
                ],
                btnFormType: true,
            },
            listQuery: {
                id: "",
                studentName: "",
                schoolId: this.$store.state.schoolId,
                attendanceDate:"",
            },
            // 学生列表分页参数
            pageQuery: {
                pageNum: 1,
                pageRow: 20,
                total: 0,
            },
            contentHeight: 0,
            treeData: [], //班级列表数据
            loadingTable: false,
            tableConfig: {
                thead: [
                    {
                        label: "学生姓名",
                        align: "center",
                        type: "slot",
                        slotName: "studentName",
                    },
                    {
                        label: "学号",
                        prop: "studentNo",
                        align: "center",
                    },
                    {
                        label: "唯一号",
                        prop: "studentCode",
                        align: "center",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                    },
                    {
                        label: "出勤状态",
                        type: "slot",
                        slotName: "status",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operate",
                        align: "center",
                        labelWidth: '210',
                    },
                ],
                check: false,
                height: "",
            },
            tableData: [], //学生列表数据
            statisticsData: {
                bedAllNum: "10",
                roomAllNum: "40",
                laveBedNym: "20",
            },
            infoDist: [
                {
                    keyName: "totalNum",
                    text: "应到",
                },
                {
                    keyName: "actualNum",
                    text: "实到",
                },
                {
                    keyName: "attendedNum",
                    text: "未到",
                },
                {
                    keyName: "leaveNum",
                    text: "请假",
                },
            ],
            treeDataOption: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
            // 设置弹窗参数
            statusSettingDialogObj: {
                title: "设置",
                dialogVisible: false,
                width: "500px",
                customClass: "stu-status-setting"
            },
            // 设置参数
            statusFormData:{
                status: "",
                stuOAStatus: "2",
                changeReasonRequired: false,
                saveTime: '',
            },
            statusOptions:[
                {
                    id:"1",
                    name:"到校"
                },
                {
                    id:"2",
                    name:"未到"
                },
                {
                    id:"3",
                    name:"前一天状态"
                },
            ],
            stuOAStatusOptions:[
                {
                    id:"1",
                    name:"是"
                },
                {
                    id:"2",
                    name:"否"
                },
            ],
            statusButtonLoading:false,
            // 出勤状态弹窗参数
           attendanceStatusDialogObj: {
                title: "修改出勤状态",
                dialogVisible: false,
                width: "440px",
                customClass: "change-attendance-status"
            },
            // 出勤状态参数
            attendanceStatusFormData:{
                id:"",
                stuId:"",
                row:{},
                updateReason: "",
            },
            attendanceStatusOptions:[
                {
                    id:"3",
                    name:"未到"
                },
                {
                    id:"1",
                    name:"到校"
                },
                {
                    id:"2",
                    name:"请假"
                },
            ],
            attendanceStatusButtonLoading:false,
            defaultExpandIds:[],
            treeKey:1,
            isHasSchoolLeaveRecordAnalysisMenu: false,
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            schoolStageList: (state) => state.schoolStageList,
            menuList: (state) => state.menuList,
        }),
        // 判断是否是当天，返回布尔值
        isToday() {
            let today = this.$moment().format("YYYY-MM-DD");
            let attendanceDate = this.listQuery.attendanceDate;
            return today == attendanceDate
        },
        stuN() {
            return require("@/assets/images/stuN.png");
        },
        stuY() {
            return require("@/assets/images/stuY.png");
        },
        teaN() {
            return require("@/assets/images/teaN.png");
        },
        teaY() {
            return require("@/assets/images/teaY.png");
        },
    },
    watch: {
        role: {
            handler(val) {
                this.formData.data = val == "teacher" ? [...this.teacherFormData] : [...this.teacherFormData, ...this.studentFormData];
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.init();
        this.contentHeight = document.body.clientHeight - this.$refs.table.$el.offsetTop - 80;
    },
    beforeDestroy() {
        if (this.$route.query && this.$route.query.isRemerber) {
            let newQuery = this._.cloneDeep(this.$route.query)
            delete newQuery.isRemerber
            this.$router.replace({ query: newQuery })
        }
        console.log('this.$route.query',this.$route.query);
        
    },
    methods: {
        async init(){
            console.log('menuListmenuListmenuListmenuList',this.menuList);
            // isHasSchoolLeaveRecordAnalysisMenu 
            // 判断是否有请假统计菜单权限
            if (this.menuList && this.menuList.length > 0) {
                let statisticsList = this.menuList.filter(item => item.menuId == '020604');
                if(statisticsList.length > 0) {
                    this.isHasSchoolLeaveRecordAnalysisMenu = true;
                }
            }
            // 是否显示标识检索字段
            this.setUndiscipline();
            // 学生列表的级部、年级、班级的显示判断
            if(this.$store.state.gradeLevel == 7 || this.$store.state.gradeLevel == 6) {
                // 获取年级的列序号
                let gradeColumnIndex = this.tableConfig.thead.findIndex((item) => item.prop == "gradeName");
                // 7-级部在年级之前  6-级部在年级之后
                this.tableConfig.thead.splice(
                    this.$store.state.gradeLevel == 7 ? gradeColumnIndex : gradeColumnIndex+1,
                    0, 
                    {
                        label: "级部",
                        prop: "departmentName",
                        align: "center",
                    }
                )
            }
            // 获取状态设置
            await this.getStatusSettting();
            console.log('this.$route.query',this.$route.query.isRemerber);

            // 判断是否是从学生请假详情页面返回回来的
            if (this.$route.query && this.$route.query.isRemerber) {
                this.role = 'student'
                // 获取班级
                this.getOrg();
                // 是否显示标识检索字段
                await this.setUndiscipline();
                console.log('this.studentFormData',this.studentFormData);
                
                let myFormData = JSON.parse(sessionStorage.getItem('studentStatusFormData'))
                this.formData.data.forEach((item) => {
                    if(item.key === "organId") {
                        item.value = myFormData[item.key].split(",");
                        this.listQuery[item.key] = myFormData[item.key];
                    } else {
                        item.value = myFormData[item.key];
                        this.listQuery[item.key] = myFormData[item.key];
                    }
                });
                console.log('myFormData@@@@@@@@@@@@@@@@@',myFormData);
                
                
                // 获取数据: 表格和总计
                // 如果开启了学生状态与请假关联
                if (this.statusFormData.stuOAStatus == 1) {
                    let currentTimeSt = new Date().getTime();
                    console.log('currentTimeSt',currentTimeSt,'this.statusFormData.saveTime',this.statusFormData.saveTime);
                    // 1.先判断当前时间是否大于等于保存设置的时间
                    if (currentTimeSt > this.statusFormData.saveTime) {
                        // 2.调用处理学生状态根据请假单的接口
                        this.getStuOALeaveRecord();
                    }
                } else {
                    this.getStudentList();
                }
                this.getTotal();
                this.saveFilterOtions();
            } else {
                
                let recordsList = new RecordsList();
                if(recordsList.get(this.$route.name)){
                    let data = recordsList.get(this.$route.name);
                    console.log("recordsList-data", data);
                    // this.formData = data.formData;
                    data.formData.data.forEach((item) => {
                        if(item.key === "attendanceDate" || item.key === "organId") {
                            this.teacherFormData.forEach(j=>{
                                if(j.key === item.key) {
                                    j.value = item.value;
                                }
                            })
                        } else {
                            this.studentFormData.forEach(j=>{
                                if(j.key === item.key) {
                                    j.value = item.value;
                                }
                            })
                        }
                    })
                    this.listQuery = data.listQuery
                }
                this.teacherFormData.forEach((item) => {
                    if(item.key === "attendanceDate") {
                        item.value = this.$moment().format("YYYY-MM-DD");
                        this.listQuery[item.key] = item.value;
                    }
                });
                if(sessionStorage.getItem("summaryStudentStatusRole")) {
                    this.role = sessionStorage.getItem("summaryStudentStatusRole");
                    sessionStorage.removeItem("summaryStudentStatusRole");
                } else {
                    this.role = 'teacher';
                    if (this.statusFormData.stuOAStatus == 1) {
                        this.getStuOALeaveRecordClass();
                    } else {
                        this.getTableTreeData();
                    }
                }
                // 获取班级
                this.getOrg();
                // 获取数据: 表格和总计
                this.getData();
            }

        },
        /**
         * @Description: 处理从sessionStorage中取值，保留之前的筛选项及打开弹窗
         * @Author: 闫乔
         * @Date: 2025-01-23 17:30:02
         */        
         getBeforeData() {
            // 去sessionStorage中取值
            if (JSON.parse(sessionStorage.getItem('studentStatusInfo'))) {
                let studentStatusInfo = JSON.parse(sessionStorage.getItem('studentStatusInfo'))
                let currentTab = JSON.parse(sessionStorage.getItem('currentTab'))
                // 保留之前的标签
                this.tabList.forEach((item, index) => {
                    if (item.id === currentTab.id) {
                        this.$set(item, "sel", true);
                        this.currentTab = item;
                    } else {
                        this.$set(item, "sel", false);
                    }
                });
            }
        },
        /**
         * @Description: 获取
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2024-09-06 10:49:46
         */
        getData(){
            this.formData.data.forEach((item) => {
                if(item.key === "attendanceDate") {
                    if(!item.value) item.value = this.$moment().format("YYYY-MM-DD");
                    this.listQuery[item.key] = item.value;
                } else if(item.key === "organId") {
                    this.listQuery[item.key] = item.value.toString();
                } else {
                    this.listQuery[item.key] = item.value;
                }
            });
            console.log("this.listQuery", this.listQuery);
            if(this.role == "teacher") {
                if (this.statusFormData.stuOAStatus == 1) {
                    let currentTimeSt = new Date().getTime();
                    console.log('currentTimeSt',currentTimeSt,'this.statusFormData.saveTime',this.statusFormData.saveTime);
                    // 1.先判断当前时间是否大于等于保存设置的时间
                    if (currentTimeSt > this.statusFormData.saveTime) {
                        // 2.调用处理学生状态根据请假单的接口
                        this.getStuOALeaveRecordClass();
                    }
                } else {
                    this.getTableTreeData();
                }
                this.defaultExpandIds = this.listQuery.organId ? this.listQuery.organId.split(",") : [];
            } else {
                console.log('this.statusFormData.stuOAStatus_______________________________',this.statusFormData.stuOAStatus);

                // 如果开启了学生状态与请假关联
                if (this.statusFormData.stuOAStatus == 1) {
                    let currentTimeSt = new Date().getTime();
                    console.log('currentTimeSt',currentTimeSt,'this.statusFormData.saveTime',this.statusFormData.saveTime);
                    // 1.先判断当前时间是否大于等于保存设置的时间
                    if (currentTimeSt > this.statusFormData.saveTime) {
                        console.log('大于了');
                        // 2.调用处理学生状态根据请假单的接口
                        this.getStuOALeaveRecord();
                    }
                } else {
                    this.getStudentList();
                }
            }
            this.getTotal();
            this.saveFilterOtions();
        },
        setUndiscipline(){
            let isHavePer = hasPermission(["updateStudentDisciplinary"])
            let obj = {
                type: "select",
                label: "",
                value: "",
                placeholder: "标识",
                key: "undiscipline",
                list: [
                    {
                        label: "正常",
                        value: "2",
                    },
                    {
                        label: "违纪",
                        value: "1",
                    },
                ],
            };
            let isHaveFilter = this.studentFormData.some((item) => {
                return item.key == "undiscipline";
            });
            if(isHavePer){
                // 添加标识列
                this.tableConfig.thead.splice(this.tableConfig.thead.length-1,0, {
                    label: "标识",
                    type: "slot",
                    slotName: "flag",
                    align: "center",
                },)
                // 添加标识筛选条件
                if(!isHaveFilter) {
                    let formDatalength = this.studentFormData.length;
                    this.studentFormData.splice(formDatalength-1, 0, obj);
                }
            }
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.pageQuery.pageNum = 1;
                    this.getData();
                    break;
                case "reset": // 重置
                    this.pageQuery.pageNum = 1;
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getData();
                    break;
                case "setting": // 设置
                    this.openStatusSetting();
            }
        },
        // 根据标识获取出勤状态
        getAttendancesStatusBycode(code) {
            let stausArr = [
                {
                    code: "3",
                    name: "未到",
                    type: "danger"
                },
                {
                    code: "1",
                    name: "到校",
                    type: "success"
                },
                {
                    code: "2",
                    name: "请假",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
        // 根据标识获取标识状态
        getIdentifyingByCode(code) {
            let stausArr = [
                {
                    code: "2",
                    name: "正常",
                    type: "success"
                },
                {
                    code: "1",
                    name: "违纪",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
        // 获取年级班级下拉
        getOrg() {
            let obj = {...this.listQuery};
            Object.assign(obj, {jobScope: ["1", "2", "3"]});
            console.log(obj, "[[[[objobj]]]]1111111111111111111111111",this.listQuery);
            this._fet(
                // "/school/schoolOrgan/listByCondition",
                "/school/schoolOrgan/listAuthByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.organList = res.data.data.list;
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeDataOption.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    let jsonList = JSON.stringify(this.treeDataOption.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["organId"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });

                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 展示维度切换（班级、学生）
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-09-04 14:57:26
         * @param {*} role
         */
        setRole(role) {
            if (this.role == role) return;
            this.role = role;
            this.pageQuery.pageNum = 1;
            this.formData.data.forEach((item) => {
                item.value = "";
                this.listQuery[item.key] = "";
            });
            this.getData();
            if(role === "student") {
                this.tableConfig.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 150;
            }
        },
        /**
         * @Description: 获取树形的班级列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-09-04 15:00:21
         */
        getTableTreeData() {
            this._fet(
                    "/school/schoolOrganAttendance/list",
                    this.listQuery,
                ).then(async(res) => {
                    if (res.data.code === "200") {
                        console.log(3333,res.data)
                        this.treeData = res.data.data && res.data.data.length>0 ? res.data.data : [];
                        this.loadingTable = false;
                    } else {
                        this.$message.error("数据请求失败，请重试");
                        this.loadingTable = false;
                    }
                });
        },
        
        /**
         * @Description: 
         * @Author: 闫乔
         * @Date: 2025-01-22 16:09:46
         */        
         getStuOALeaveRecord() {
            this._fet("/school/schoolStudentAttendance/detailAttendanceStatusByLeaveRecord",{
                schoolId: this.schoolId,
            })
            .then(res=>{
                if(res.data.code === "200"){
                    console.log('处理学生状态根据请假单',res.data.data);
                    this.getStudentList();
                    this.getTotal();
                }
            })
            .finally(()=>{
            })
        },
         getStuOALeaveRecordClass() {
            this._fet("/school/schoolStudentAttendance/detailAttendanceStatusByLeaveRecord",{
                schoolId: this.schoolId,
            })
            .then(res=>{
                if(res.data.code === "200"){
                    console.log('处理学生状态根据请假单',res.data.data);
                    this.getTableTreeData();
                    this.getTotal();
                }
            })
            .finally(()=>{
            })
        },
        getStudentList() {
            let param = this._.cloneDeep(this.listQuery);
            param.classId = param.organId;
            param.pageNum = this.pageQuery.pageNum;
            param.pageRow = this.pageQuery.pageRow;
            this.tableData = [];
            this.loadingTable = true;
            this._fet("/school/schoolStudentAttendance/listByPage",param)
            .then(res=>{
                console.log(4444,res.data)
                if(res.data.code === "200"){
                    this.tableData = res.data.data.list;
                    this.pageQuery.total = res.data.data.totalCount || 0;
                }
            })
            .finally(()=>{
                this.loadingTable = false;
            })
        },
        // 历史出勤
        showHistoryAttendance(row, type){
            this.saveFilterOtions();
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfo",
                name: "SummaryStudentStatusInfo",
                query: { type },
                params: { ...row }
            });
        },
        /**
         * @Description: 获取统计数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-09-05 11:53:49
         */
        getTotal(){
            let param = {
                schoolId:this.schoolId,
                attendanceDate:this.listQuery.attendanceDate
            }
            this._fet("/school/schoolOrganAttendance/getTotalByAuth",param).then((res) => {
                if (res.data.code === "200") {
                    this.statisticsData = res.data.data;
                } else {}

            }).catch((err) => {

            });
        },
        traverseTree(data,parent,customObj) {
            if (!data.length) {
                return;
            }

            data.forEach(item => {
                if (item.child && item.child.length > 0) {
                    this.traverseTree(item.child, item, customObj);
                } else {
                    item.child = item.child || [];
                    item.child.push(customObj);
                }
            });
        },
        // 获取初始化状态设置
        async getStatusSettting(){
            let param = {
                schoolId: this.schoolId,
                menuId: "030410",
                configType:"15"
            }
            await this._fet("/school/schoolUserPreferenceDynamicConfig/getSchoolConfiguration",param)
            .then((res) => {
                if (res.data.code === "200") {
                    if(res.data.data.schoolId){
                        let configInfo = res.data.data.config;
                        for (let key in configInfo) {
                            this.statusFormData[key] = configInfo[key];
                        }
                    }
                    // 如果未设置，则弹框
                    if(!this.statusFormData.status){
                        this.openStatusSetting();
                    }    
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
        openStatusSetting(){
            this.statusSettingDialogObj.dialogVisible = true
        },
        handleStatusSettingClose() {
            this.statusSettingDialogObj.dialogVisible = false
        },
        handleStatusSubmit(){
            if(!this.statusFormData.status){
                this.$message.warning("未设置,请选择");
                return;
            }
            this.statusFormData.saveTime = new Date().getTime();
            let param = {
                schoolId: this.schoolId,
                menuId: "030410",
                configType:"15",
                config: this.statusFormData,
            }
            console.log('param~~~~~~~~~~~~',param);
            this.statusButtonLoading = true
            this._fet("/school/schoolUserPreferenceDynamicConfig/saveSchoolConfiguration",param).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("设置成功");

                } else {
                    this.$message.error("设置失败");


                }
                this.statusButtonLoading = false
                console.log('this.statusFormData',this.statusFormData.stuOAStatus,'this.statusFormData.saveTime',this.statusFormData.saveTime);
                if (this.statusFormData.stuOAStatus == 1) {
                    let currentTimeSt = new Date().getTime();
                    console.log('currentTimeSt',currentTimeSt);
                    // 1.先判断当前时间是否大于等于保存设置的时间
                    if (currentTimeSt > this.statusFormData.saveTime) {
                        // 2.调用处理学生状态根据请假单的接口
                        this.getStuOALeaveRecord();
                    }
                } else {
                    this.getStudentList();
                }
                this.handleStatusSettingClose()
            }).catch((err) => {
                this.statusButtonLoading = false
                this.handleStatusSettingClose();
            });

        },
        detailsOA(item) {
            console.log('item~~~~~~~~~~~~~~~~',item);
            
            this.$router.push({
                // name: "LeaveDetail",
                path: '/schoolLeaveRecordAnalysis',
                query: {
                    onlyCode: item.studentCode,
                    role: "student",
                    stuName: item.studentName,
                    startTime: this.listQuery.attendanceDate + ' 00:00',
                    endTime: this.listQuery.attendanceDate + ' 23:59',
                    isStudentOA: true,
                },
            });
            sessionStorage.setItem('studentStatusInfo', JSON.stringify(item));
            sessionStorage.setItem('studentStatusFormData', JSON.stringify(this.listQuery));
        },
        openAttendanceStatus(stuId,status,row){
            let isHavePermission = hasPermission(["updateStudentStatus"])
            if(!isHavePermission){
                return
            }
            if(!this.isToday){
                this.$message.error("只能设置今天的学生状态");
                return;
            }
            this.attendanceStatusFormData.id = status;
            this.attendanceStatusFormData.stuId = stuId;
            this.attendanceStatusFormData.row = row;
            this.attendanceStatusFormData.updateReason = "";

            this.attendanceStatusDialogObj.dialogVisible = true
        },
        handleAttendanceStatusClose() {
            this.attendanceStatusDialogObj.dialogVisible = false
        },
        handleAttendanceStatusSubmit(){
            if(this.statusFormData.changeReasonRequired && !this.attendanceStatusFormData.updateReason){
                this.$message.warning("请填写修改原因");
                return;
            }
            let param = {
                schoolId: this.schoolId,
                studentId: this.attendanceStatusFormData.stuId,
                status:this.attendanceStatusFormData.id,
                updateReason:this.attendanceStatusFormData.updateReason,
            }
            this.attendanceStatusButtonLoading = true
            this._fet("/school/schoolStudentAttendance/updateStatus",param).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("设置成功");
                    this.$set(this.attendanceStatusFormData.row,"status",this.attendanceStatusFormData.id)
                    this.getTotal();
                } else {
                    this.$message.error(res.data.msg || "设置失败");
                }
                this.attendanceStatusButtonLoading = false
                this.handleAttendanceStatusClose()
            }).catch((err) => {
                this.attendanceStatusButtonLoading = false
                this.handleAttendanceStatusClose();
            });

        },
        handleUndisciplineClick(row) {
            if(row.undiscipline == "2"){
                // this.$message.warning("该学生已经是正常状态");
                return;
            }

            if(!this.isToday){
                this.$message.warning("只能设置今天的学生状态");
                return;
            }

            this.$confirm('修改后学生将不再记为违纪', '确认要修改为正常吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let param = {
                    id:row.studentId,
                    undiscipline:"2"
                }
                let res = await this._fet("/school/schoolStudent/updateStudentDiscipline",param);
                if(res.data.code == "200"){
                    this.$message({
                        type: 'success',
                        message: '成功!'
                    });
                    this.$set(row,"undiscipline","2")
                }

                }).catch(() => {
            // this.$message({
            //         type: 'info',
            //         message: '已取消'
            //     });
            });
        },
        // 保存筛选项
        saveFilterOtions() {
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                recordsList.remove(this.$route.name)
            }
            recordsList.set(this.$route.name,{
                formData: this.formData,
                listQuery: this.listQuery
            })
        },
        handleFilterInput:debounce(function(data){
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(keyArr.includes(data.key) && data.value) {
                    // 清空班级值
                    this.formData.data.forEach(formItem => {
                        if(formItem.key == classKey){
                            formItem.value = [];
                        }
                    })
                }
        },200),
        handleFilterCascader(data) {
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(data.data.key == classKey && data.data.value.length > 0){
                this.formData.data.forEach((item) => {
                    if(keyArr.includes(item.key)){
                        item.value = '';
                    }
                })
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    position: relative;

    .setting {
        position: absolute;
        right: 8px;
        top: 15px;
        cursor: pointer;
        color: #666;
        margin-right: 10px;
    }
}
.content {
    border-radius: 4px;
    margin-top: 2px;
    background-color: #fff;
    overflow-y: auto;
    padding-right: 10px;

    ::v-deep .el-tree {
        .el-tree-node__content {
            height: auto;
            margin-left: 10px;
        }
    }

    .custom-tree-node {
        flex: 1;
        .btns {
            border-bottom:1px solid #D7D9DB;
        }
    }

    .my-tree-content {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #ebeef5;
        width: 100%;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            color: #fff;
            background-color: #bfbfbf;
            font-size: 13px;
            transition: all 0.3s ease 0s;

            &:hover {
                background-color: #3c7fff;
            }

            &.up {
                transform: rotate(90deg);
                background-color: #3c7fff;
            }
        }

        .info {
            display: flex;
            flex: 1;
            margin-left: 10px;
            font-size: 14px;
            justify-content: space-around;
            .info-item {
                // margin-left: 100px;
            }
        }

        .title {
            font-weight: bold;
            margin-left: 10px;
            font-size: 14px;
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


    }

    .sepcial-node {
            cursor: auto;
        }
}
// 班级列表、学生列表切换按钮样式
.teacher-student {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div {
        width: 62px;
        height: 30px;
        border: 1px solid #C7C7C7;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 18px;
            height: 18px;
        }
    }
    div:hover {
        background: #87aef7;
        cursor: pointer;
    }
    .teacher {
        border-radius: 4px 0px 0px 4px;
    }
    .student {
        border-radius: 0px 4px 4px 0px;
    }
    .active-style {
        background: #3C7FFF;
    }
}
// 设置弹窗样式
::v-deep .stu-status-setting {
    .el-dialog__body {
        padding: 20px;
        margin-top: 0;
        color: #2B2F33;

        .setting-item {

            .title {
                background: #F7F8FA;
                border-radius: 2px;
                padding: 8px 13px;
                color: #2B2F33;
                font-weight: bold;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 2px;
                    height: 13px;
                    background: #3C7FFF;
                    border-radius: 1px;
                    margin-right: 5px;
                }
            }

            .el-form-item {
                padding-left: 30px;
                margin-bottom: 20px;
            }

            &.init-status {
                margin-bottom: 40px;

                .tip {
                    i {
                        color: #F7B646;
                        margin: 0 5px 0 10px;
                    }

                    .tip-text {
                        font-size: 12px;
                        color: #999999;
                    }
                }

                .require-tip {
                    color: #F37373;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            &.change-require {
                // margin-bottom: 85px;

                .el-switch {
                    transform: scale(0.85) translate(-4px, 0);
                }
            }
        }

    }
}

// 修改出勤状态弹窗样式
::v-deep .change-attendance-status {
    .el-dialog__body {
        padding: 20px;
        margin-top: 0;
        color: #2B2F33;

        .el-form-item {
            padding-left: 10px;
            margin-bottom: 20px;

            .label {
                display: block;
                line-height: 14px;

                &.required {
                    &::before {
                        content: '*';
                        color: #F37373;
                        margin-right: 5px;

                    }
                }
            }

            .el-textarea {
                margin: 10px 0;

                .el-textarea__inner {
                    font-family: Microsoft YaHei;
                }
            }
        }
    }
}

::v-deep .el-link.el-link--warning {
    color:#c600fb
}
</style>
